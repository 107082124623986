<template>
  <div>
    <ChangeParkingInformation
      v-model="parkings"
      :checkInDate="booking.checkInDate"
      :checkOutDate="booking.checkOutDate"
      :facilityId="booking.room.roomType.facility.id"
      :currentReservationParkingId="currentReservationParkingId"
      @notAvailable="notAvailable = true"
      change
    >
      <template slot="notAvailableMessage">
        {{ $t('common.parkingInformationNotAvailableMessage') }}
      </template>
    </ChangeParkingInformation>
    <AppBtn
      @click="changeParkingReservations"
      :disabled="!canSave"
    >
      {{ $t("reservationAndUsageHistory.changeToTheAboveContent") }}
    </AppBtn>
  </div>
</template>

<script>
import { selectedBookingComputed } from '../../selected-booking.mixin'
import ChangeParkingInformation from '@/components/ReservationComponents/ChangeParkingInformation.vue'
import AppBtn from '../../../../components/Common/AppBtn.vue'

export default {
  name: 'GuestInformationChange',
  mixins: [selectedBookingComputed],
  components: {
    ChangeParkingInformation,
    AppBtn
  },
  methods: {
    async changeParkingReservations () {
      await this.$doLoading(async () => {
        await this.$showGqlError(async () => {
          await this.$store.dispatch('updateParkings', {
            bookingId: this.id,
            parkings: this.parkings
          })
        })
      })

      this.completeChange()
    }
  },
  data () {
    return {
      parkings: [],
      currentReservationParkingId: null
    }
  },
  computed: {
    canSave () {
      if (typeof this.parkings === 'number') {
        // non-faciilty managed parkings. no need to validate
        return true
      } else {
        return !this.parkings.some(p => !p.parking?.id || !p.fromDate || !p.toDate)
      }
    }
  },
  watch: {
    booking: {
      immediate: true,
      handler (booking) {
        if (booking.parkings && typeof booking.parkingRequestedNumber === 'number') {
          this.parkings = booking.parkingRequestedNumber
        }
        this.parkings = booking.parkings.map(p => ({
          ...p,
          parking: {
            id: p.facilityParkingId
          }
        }))
        if (booking.parkings.length) {
          this.currentReservationParkingId = booking.parkings[0].facilityParkingId
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
select {
  border-radius: 100px;
  width: 100%;
  height: 57px;
  border: 1px solid #E0E0E0 !important;
  outline: none;
}
.guest-form-header{
  height: 55px;
}
</style>
