import { render, staticRenderFns } from "./ModalDated.vue?vue&type=template&id=3512e7bb&scoped=true&"
import script from "./ModalDated.vue?vue&type=script&lang=js&"
export * from "./ModalDated.vue?vue&type=script&lang=js&"
import style0 from "./ModalDated.vue?vue&type=style&index=0&id=3512e7bb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3512e7bb",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
installComponents(component, {VBtn,VDialog,VRadio,VRadioGroup})
