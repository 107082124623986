<template>
  <div class="d-flex my-3">
    <div
      :for="this.for"
      class="text-medium --bg mr-3 d-flex align-center"
      :class="disabled ? '--gray' : ''"
    >
      {{ label }}
      <slot name="label" />
    </div>
    <div
      v-if="required"
      class="text-small text-center --white bg-pink rounded-4 px-2 py-1"
    >
      {{ $t("rules.required") }}
    </div>
    <div
      v-else-if="withAny"
      class="text-small text-center --white bg-grey rounded-4 px-2 py-1"
    >
      {{ $t("common.any") }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    required: {
      type: Boolean,
      required: false
    },
    withAny: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    for: {
      type: String,
      required: false
    }
  }
}
</script>
