<template>
  <div class="px-3">
    <v-btn v-bind="overrideProps" v-on="listeners">
      <template v-for="(index, name) in $slots" v-slot:[name]>
        <slot :name="name" />
      </template>
      <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
        <slot :name="name" v-bind="data"></slot>
      </template>
      <template v-slot:default>
        <slot />
      </template>
    </v-btn>
  </div>
</template>

<script>
import { VBtn } from 'vuetify/lib'
const props = {
  noDc: Boolean,
  thick: Boolean
}
export default {
  mixins: [VBtn],
  props,
  data () {
    return {
      doubleClickPrevent: false
    }
  },
  computed: {
    baseBindValues () {
      const propsCopied = { ...this.$props }
      Object.keys(props).forEach(key => {
        delete propsCopied[key]
      })
      return {
        ...propsCopied,
        ...this.$attrs
      }
    },
    listeners () {
      const { click, ...rest } = this.$listeners ?? {}

      return {
        ...rest,
        click: this.wrapClick(click)
      }
    },
    overrideProps () {
      const { disabled, ...rest } = this.baseBindValues

      return {
        ...rest,
        block: true,
        xLarge: true,
        rounded: true,
        color: 'btn-grad--orange',
        class: 'text-title --white mb-3',
        elevation: '0',
        disabled: this.doubleClickPrevent || disabled
      }
    }
  },
  methods: {
    wrapClick (click) {
      return (...args) => {
        if (this.noDc) {
          this.doubleClickPrevent = true
          setTimeout(() => {
            this.doubleClickPrevent = false
          }, 1000)
        }
        return click(...args)
      }
    }
  }
}
</script>
